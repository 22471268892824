.product__item {
  display: flex;
  flex-flow: column nowrap;
  .img__container {
    height: 75%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text__content {
    a {
      color: inherit;
      text-decoration: none;
    }
    .product__name {
      margin-top: 10px;
      font-size: 20px;
      font-weight: bold;
    }
    .product__price {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
}
