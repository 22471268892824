.payment__details {
  max-width: 1000px;
  width: 100%;
  margin: auto;
  padding-top: 20px;
  form {
    h2 {
      margin: 20px 0;
    }
    .card__element {
      margin-bottom: 20px;
    }
  }
}
