@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap");
header {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header .container {
  width: 85%;
  margin: auto;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

header .container .logo__container a {
  text-decoration: none;
}

header .container .logo__container a h1 {
  color: #2b3945;
}

header .container nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

header .container nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
}

header .container nav ul li {
  padding-left: 15px;
}

header .container nav ul li a {
  text-decoration: none;
  color: #2b3945;
  font-size: 18px;
  cursor: pointer;
}

header .container .registration .registration__list {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .container .registration .registration__list li {
  padding-left: 15px;
}

header .container .registration .registration__list a {
  color: black !important;
  text-decoration: none;
}

header .container .registration .registration__list a li.cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

header .container .registration .registration__list li a,
header .container .registration .registration__list .log__out {
  text-decoration: none;
  color: #2b3945;
  font-size: 18px;
  cursor: pointer;
}

.directory {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: calc(80vh);
      grid-template-rows: calc(80vh);
  margin: 30px auto;
  border-radius: 5px;
  overflow: hidden;
}

.directory::after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.directory img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.directory .men__item__container {
  position: relative;
  background: url("../../assets/images/shop-men.jpeg") no-repeat 0 -180px;
  background-size: cover;
}

.directory .women__item__container {
  position: relative;
  background: url("../../assets/images/shop-women.jpeg") no-repeat;
  background-size: cover;
}

.directory .shop__button {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 100;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 18px;
  padding: 15px 30px;
  border-radius: 10px;
  outline: none;
  font-family: inherit;
  background-color: #2b3945;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.directory .shop__button:hover {
  opacity: 0.85;
}

footer {
  text-align: center;
  font-size: 16px;
  padding: 20px;
  margin-top: 50px;
}

.btn {
  width: 100%;
  background-color: #2b3945;
  color: white;
  font-family: inherit;
  border-radius: 5px;
  font-size: 18px;
  border: 2px solid #2b3945;
  outline: none;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.btn:hover {
  background-color: #fff;
  color: #2b3945;
}

.form__input__container .form__input,

.main__form {
  margin-top: 50px;
}

.main__form .container {
  max-width: 400px;
  margin: auto;
  overflow: hidden;
  border: 2px solid #2b3945;
  padding: 15px 15px 10px 15px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.main__form .container form {
  width: 100%;
}

.main__form .container form .form__inputs {
  padding-bottom: 5px;
  width: 100%;
}

.main__form .container span {
  font-size: 20px;
  padding: 5px 0;
  font-style: italic;
}

.main__form .container h2 {
  padding-bottom: 15px;
}

.main__form .container .password__recovery h3 {
  font-size: 16px;
  font-family: inherit;
  font-weight: 400;
  margin-bottom: 10px;
}

.social__signin,
.common__signin {
  width: 100%;
}

.account h1 {
  margin: 20px 15px;
}

.admin {
  padding: 20px;
}

.admin .call__to__actions {
  display: inline-block;
}

.admin ul li {
  list-style: none;
}

.admin .manage__products {
  margin-top: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(200px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(200px, 350px);
  gap: 30px;
}

.admin .manage__products .product__admin__card {
  border: 2px solid #2b3945;
  border-radius: 10px;
  overflow: hidden;
}

.admin .manage__products .product__admin__card .img__container {
  height: 50%;
}

.admin .manage__products .product__admin__card .img__container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-bottom: 2px solid #2b3945;
}

.admin .manage__products .product__admin__card .text__content {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.admin .manage__products .product__admin__card .text__content .delete__icon {
  cursor: pointer;
}

.admin .btn {
  margin-top: 50px;
}

.admin__toolbar {
  width: 100%;
  background-color: #2b3945;
  height: auto;
}

.admin__toolbar .container {
  width: 85%;
  overflow: hidden;
  margin: auto;
}

.admin__toolbar .container ul {
  text-align: right;
  list-style: none;
}

.admin__toolbar .container li a {
  line-height: 2;
  color: white;
  text-decoration: none;
}

.admin__toolbar .container li a:hover {
  text-decoration: underline;
}

.vertical__nav ul {
  list-style: none;
  font-size: 18px;
}

.vertical__nav ul .home,
.vertical__nav ul .sign__out {
  line-height: 2.5;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 0 10px;
  cursor: pointer;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}

.vertical__nav ul .home:hover,
.vertical__nav ul .sign__out:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: #fff;
}

.vertical__nav ul li a {
  display: block;
  text-decoration: none;
  color: #000;
}

.user__profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 30px 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  text-align: center;
}

.user__profile li {
  list-style: none;
}

.user__profile li .img__container {
  width: 80px;
  margin: 0px auto 10px auto;
}

.user__profile li .img__container img {
  width: 100%;
}

.user__profile li span {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
}

.form__row {
  display: inline-block;
  width: 100%;
}

.form__row label {
  display: block;
  width: 100%;
  text-align: left;
}

.form__row select {
  display: block;
  width: auto;
  float: left;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  text-align: left;
  padding: 5px;
  margin: 10px auto;
  outline: none;
  border: 2px solid #000;
  border-radius: 4px;
  cursor: pointer;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal__wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
}

.modal__wrap .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  width: 95%;
  border-radius: 5px;
  padding: 2rem;
  max-width: 60rem;
  height: auto;
  pointer-events: all;
  max-height: 95vh;
  overflow: auto;
}

.modal__wrap .modal .form__input {
  margin: 10px auto;
}

.products h1 {
  margin: 30px 0;
}

.products .products__grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(300px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  row-gap: 100px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  grid-auto-rows: minmax(250px, 400px);
  margin-bottom: 100px;
}

.product__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
}

.product__item .img__container {
  height: 75%;
}

.product__item .img__container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.product__item .text__content a {
  color: inherit;
  text-decoration: none;
}

.product__item .text__content .product__name {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}

.product__item .text__content .product__price {
  margin-top: 5px;
  margin-bottom: 10px;
}

.product__card {
  max-width: 700px;
  margin: 30px auto;
}

.product__card .product__hero img {
  max-height: 500px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.product__card .product__details .product__name {
  font-size: 30px;
  font-weight: bold;
}

.product__card .product__details .product__price {
  font-size: 20px;
  margin-top: 10px;
}

.product__card .product__details .product__description {
  display: block;
  margin-top: 20px;
}

.product__card .add__to__cart {
  display: inline-block;
  min-width: 30%;
  margin-top: 10px;
}


.payment__details {
  max-width: 1000px;
  width: 100%;
  margin: auto;
  padding-top: 20px;
}

.payment__details form h2 {
  margin: 20px 0;
}

.payment__details form .card__element {
  margin-bottom: 20px;
}

html,
body {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.app__container {
  min-height: 100vh;
  overflow: hidden;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
}

.app__container .wrapper {
  min-width: 250px;
  max-width: 1536px;
  padding-right: 24px;
  padding-left: 24px;
  width: 85%;
  margin: auto;
}

.admin__layout .control__panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 90vh;
}

.admin__layout .control__panel .sidebar {
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  width: 20%;
}

.admin__layout .content {
  width: 100%;
}
