//mixins
@mixin flexBoxCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
//styles
.user__profile {
  @include flexBoxCenter();
  padding: 30px 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  li {
    list-style: none;
    .img__container {
      width: 80px;
      margin: 0px auto 10px auto;
      img {
        width: 100%;
      }
    }
    span {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 18px;
    }
  }
}
