// //mixins
// @mixin flexBoxSpaceBetween {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }
// //header
// header {
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
//   height: 70px;
//   display: flex;
//   align-items: center;
//   .container {
//     width: 85%;
//     margin: auto;
//     overflow: hidden;
//     @include flexBoxSpaceBetween();
//     .logo__container {
//       a {
//         text-decoration: none;
//         h1 {
//           color: $dark-blue;
//         }
//       }
//     }
//     nav {
//       display: flex;
//       justify-content: space-around;
//       ul {
//         display: flex;
//         list-style: none;
//         li {
//           padding-left: 15px;
//           a {
//             text-decoration: none;
//             color: $dark-blue;
//             font-size: 18px;
//             cursor: pointer;
//           }
//         }
//       }
//     }
//     .registration {
//       .registration__list {
//         list-style: none;
//         display: flex;
//         li {
//           padding-left: 15px;
//         }
//         a {
//           color: black !important;
//           text-decoration: none;
//           li.cart {
//             display: flex;
//             gap: 10px;
//             align-items: center;
//             justify-content: center;
//             cursor: pointer;
//           }
//         }
//         li a,
//         .log__out {
//           text-decoration: none;
//           color: $dark-blue;
//           font-size: 18px;
//           cursor: pointer;
//         }
//       }
//     }
//   }
// }
