.form__row {
  display: inline-block;
  width: 100%;
  label {
    display: block;
    width: 100%;
    text-align: left;
  }
  select {
    display: block;
    width: auto;
    float: left;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    text-align: left;
    padding: 5px;
    margin: 10px auto;
    outline: none;
    border: 2px solid #000;
    border-radius: 4px;
    cursor: pointer;
  }
}
