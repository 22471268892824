.products {
  h1 {
    margin: 30px 0;
  }
  .products__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    row-gap: 100px;
    column-gap: 50px;
    grid-auto-rows: minmax(250px, 400px);
    margin-bottom: 100px;
  }
}
