.product__card {
  max-width: 700px;
  margin: 30px auto;
  .product__hero {
    img {
      max-height: 500px;
      width: 100%;
      object-fit: cover;
    }
  }
  .product__details {
    .product__name {
      font-size: 30px;
      font-weight: bold;
    }
    .product__price {
      font-size: 20px;
      margin-top: 10px;
    }
    .product__description {
      display: block;
      margin-top: 20px;
    }
  }
  .add__to__cart {
    display: inline-block;
    min-width: 30%;
    margin-top: 10px;
  }
}
