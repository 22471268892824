@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
@import './variables';
@import '../Header/header';
@import '../Directory/directory';
@import '../../pages/Registration/registration';
@import '../Footer/footer';
@import '../../pages/Login/login';
@import '../SignIn/signin';
@import '../Forms/Button/button';
@import '../SignUp/signup';
@import '../Forms/FormInput/form-input';
@import '../../pages/Recovery/recovery';
@import '../Forms/MainForm/main-form';
@import '../PasswordRecovery/password-recovery';
@import '../../pages/Account/account';
@import '../../pages/Admin/admin';
@import '../AdminToolbar/admin-toolbar';
@import '../VerticalNav/vertical-nav';
@import '../UserProfile/user-profile';
@import '../Forms/FormSelect/form-select';
@import '../Modal/modal';
@import '../../pages/Search/search';
@import '../Products/products';
@import '../Products/Product/product';
@import '../LoadMore/loadmore';
@import '../ProductCard/product-card';
@import '../Checkout/checkout';
@import '../PaymentDetails/payment-details';
html,
body {
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app__container {
  min-height: 100vh;
  overflow: hidden;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  .wrapper {
    min-width: 250px;
    max-width: 2000px;
    width: 85%;
    margin: auto;
  }
}

.admin__layout {
  .control__panel {
    display: flex;
    min-height: 90vh;
    .sidebar {
      border-right: 2px solid rgba(0, 0, 0, 0.1);
      width: 20%;
    }
  }
  .content {
    width: 100%;
  }
}
