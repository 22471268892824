//mixins
@mixin flexBoxColumn {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.main__form {
  .container {
    margin: auto;
    overflow: hidden;
    padding: 15px 15px 10px 15px;
    border-radius: 5px;
    @include flexBoxColumn();
    form {
      width: 100%;
      .form__inputs {
        padding-bottom: 5px;
        width: 100%;
      }
    }
    span {
      font-size: 20px;
      padding: 5px 0;
      font-style: italic;
    }
    h2 {
      padding-bottom: 15px;
    }
    .password__recovery h3 {
      font-size: 16px;
      font-family: inherit;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
}
