.form__input__container {
  .form__input,
  &.checkout__input select {
    width: 100%;
    font-family: inherit;
    padding: 15px 20px;
    font-size: 16px;
    margin: 5px 0;
    border-radius: 5px;
    outline: none;
    border: 2px solid $dark-blue;
  }
}
