//mixins
@mixin flexBoxSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin {
  padding: 20px;
  .call__to__actions {
    display: inline-block;
  }
  ul li {
    list-style: none;
  }
  .manage__products {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: minmax(200px, 350px);
    gap: 30px;
    .product__admin__card {
      border: 2px solid $dark-blue;
      border-radius: 10px;
      overflow: hidden;
      .img__container {
        height: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-bottom: 2px solid $dark-blue;
        }
      }
      .text__content {
        padding: 20px;
        @include flexBoxSpaceBetween();
        .delete__icon {
          cursor: pointer;
        }
      }
    }
  }
  .btn {
    margin-top: 50px;
  }
}
